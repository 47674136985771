<template>
  <div>
    <div v-if="loading" id="content-area" class="align-center">
      <h1>{{loadingMessage}}</h1>
    </div>
    <template v-else>
      <div id="hero">
        <h1 id="page-title">Mål jeres arbejdsfællesskab</h1>
        <h1 id="page-title-mobile">Mål jeres arbejds-fællesskab</h1>
      </div>
      <div id="content-area" class="align-center">
        <!-- <img :src="coverImageUrl" alt="" class="cover-image"> -->
        <div class="sections">
          <section v-if="!loggedIn">
            <div class="section-content">
              <h2>Det tager kun 5 minutter at gennemføre målingen</h2>
              <p>I sender nemt målingen til medarbejderne ved at sende et link til spørgeskemaet. Når medarbejderne har besvaret spørgeskemaet, får I automatisk en rapport med resultatet og anbefalinger til konkrete aktiviteter, I kan implementere.</p>
              <p>Tag selv en demo for at se spørgsmålene og rapporten med anbefalinger.</p>
              <div class="horizontal-flex">
                <base-ui-button v-if="!userHasResponse" @clicked="$router.push('/survey')" primary :class="bounce ? 'bounce' : ''">Demo (ca. 5 min)</base-ui-button>
                <base-ui-button v-if="userHasResponse" @clicked="$router.push('/survey')">Demo besvarelse</base-ui-button>
                <base-ui-button v-if="userHasResponse" @clicked="$router.push('/rapport/personlig')">Demo rapport</base-ui-button>
              </div>
            </div>
            <div class="section-image">
              <img :src="sectionPrivatImage" alt="" class="">
            </div>
          </section>
          <section>
            <div class="section-image">
              <img :src="sectionOrgImage" alt="" class="">
            </div>
            <div class="section-content">
              <h2>Kulturmåling</h2>
              <p v-if="!loggedIn">Mål arbejdsfællesskabet i jeres virksomhed. Klik på ‘Start kulturmåling’ for at oprette en gratis profil og få et link, I nemt kan sende ud til medarbejderne i virksomheden.</p>
              <!-- <p v-if="!loggedIn">Log ind for at se eksisterende måling, resultater og anbefalinger, samt få link til deling af spørgeskema i din organisation.</p> -->
              <p v-if="loggedIn" >Hvis I vil have et billede af arbejdsfællesskabet i virksomheden, så bed jeres medarbejdere om at tage kulturmålingen. I kan løbende se, hvordan resultatet udvikler sig.</p>
              <div class="horizontal-flex">
                <base-ui-button v-if="!loggedIn" @clicked="$router.push('/login')">Start kulturmåling</base-ui-button>
                <base-ui-button v-if="loggedIn" @clicked="$router.push('/del')" primary>Send spørgeskemaet ud</base-ui-button>
                <base-ui-button v-if="loggedIn" @clicked="$router.push('/rapport/virksomhed')">Se resultat</base-ui-button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseUiButton from '../components/shared/BaseUiButton.vue'
import illustration1 from '../assets/imgs/illustration1.svg'
import illustration2 from '../assets/imgs/illustration3.svg'
import anime from 'animejs/lib/anime.es.js'

export default {
  components: { BaseUiButton },
  emits: ['update-background'],
  data () {
    return {
      loading: true,
      loadingMessage: 'Loader...',
      coverImageUrl: illustration1,
      sectionPrivatImage: illustration1,
      sectionOrgImage: illustration2,
      bounce: false
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.user.loggedIn
    },
    userHasResponse () {
      return this.$store.state.response.data && this.$store.state.response.data.completed
    }
  },
  mounted () {
    this.$emit('update-background', 'rgb(253,252,248)')
    // this.$emit('update-background', '#2a6889')
    this.loading = false
    this.$nextTick(() => {
      anime({
        targets: '#page-title',
        opacity: [0, 1],
        easing: 'linear',
        duration: 650
      })
    })
    setTimeout(() => {
      this.bounce = true
    }, 10000)
  },
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
#page-title-mobile
  display: none;
#content-area
  padding-top 0px

#hero
  background rgb(237, 118, 94)

#hero h1
  font-size 2.8vw
  color rgb(253, 252, 248)

.cover-image
  margin-bottom 1.5rem

.sections
  text-align left

section
  display flex
  align-items center
  > *:not(:last-child)
    margin-right 4rem

.section-content
  flex-basis 70%
  flex-grow 1

.section-image
  flex-basis 30%
  padding-top 2rem
  img
    width 100%

.buttons
  display flex

@media screen and (max-width: 480px) {
  #hero h1 {
    font-size: 5.8vw;
    color: rgb(253, 252, 248);
  }
  .sections {
    text-align: center;
  }
  .section-image {
    display: none;
  }
  .section-content {
    flex-basis: 100%;
    margin-right: 0 !important;
  }
  #page-title {
    display: none;
  }
  #page-title-mobile {
    display: block;
  }
}

</style>
