<template>
  <div id="content-area">
    <h1>Profil</h1>
    <nav class="menu-items">
      <div v-if="loggedIn">
        <p>Du er logget ind med emailen: <em>{{email}}</em></p>
      </div>
      <router-link to="/login" v-if="!loggedIn" class="menu-item">
        <p>Log ind for at se eksisterende målinger, resultater og anbefalinger og få link til at dele spørgeskemaet i virksomheden.</p>
        <h2>Log ind</h2>
      </router-link>
      <router-link to="/nulstil-password" v-if="loggedIn" class="menu-item">
        <h2>Skift / nulstil password</h2>
      </router-link>
      <router-link to="" v-if="loggedIn" @click="signOut" class="menu-item">
        <h2>Log ud</h2>
      </router-link>
      <router-link to="/arkiv" v-if="loggedIn" class="menu-item">
        <h2>Mit arkiv</h2>
      </router-link>
      <router-link to="/slet-profil" v-if="loggedIn" class="menu-item">
        <h2>Slet profil</h2>
      </router-link>
    </nav>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  computed: {
    email () {
      return this.$store.state.user.data.email
    },
    loggedIn () {
      return this.$store.state.user.loggedIn
    }
  },
  methods: {
    signOut () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.dispatch('survey/clear')
          this.$store.dispatch('response/clear')
          this.$router.push('/logud')
          console.log('logged out')
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.menu-items
  display flex
  flex-flow column
  align-items center

.menu-item
  margin 0
  padding 1rem .5rem
  width 100%
  border-top 1px solid white
  transition background-color .4s linear

a.menu-item
a.menu-item:visited
  text-decoration none

.menu-item:hover
  cursor pointer
  background-color rgba(255,255,255,.07)

.menu-item:last-child
  border-bottom 1px solid white

</style>
